import { useContext, useEffect, useState } from "react"
import axios from "axios"
import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import DropdownInput from "../../common/components/forms/inputs/DropdownInput"
import { AuthContext } from "../../App"

// const repositoryFullNameSplit = repositoryFullName.split("/")

const GithubSelectBranch = ({
  register,
  repositoryOwner,
  repositoryName,
  onChange,
}) => {
  const { state } = useContext(AuthContext)

  const [repositoryBranches, setRepositoryBranches] = useState([])

  useEffect(() => {
    const fetchBranches = async (repositoryOwner, repositoryName) => {
      const access_token = state.user.access_token

      const results = await axios
        .get(
          `${process.env.REACT_APP_TRON_URL}/github/repositories/${repositoryOwner}/${repositoryName}/branches`,
          {
            params: {
              access_token,
            },
          }
        )
        .catch((error) => {
          console.log(error)
        })

      setRepositoryBranches(results.data)
    }
    fetchBranches(repositoryOwner, repositoryName)
  }, [state.user.access_token, repositoryOwner, repositoryName])

  return (
    <>
      <Grid item xs={12}>
        <Box mb={2}>
          <DropdownInput
            label="Repository Branches"
            name="branch"
            options={repositoryBranches.map((branch) => branch.name)}
            defaultValue=""
            register={register}
            onChange={onChange}
          />
        </Box>
      </Grid>
    </>
  )
}

export default GithubSelectBranch
