import React, { useState, useEffect, useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthContext } from "../App"
import {
  Box,
  Button,
  Grid,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
} from "@mui/material"
import { CARDS_COLOR, SECONDARY_MAIN } from "../common/layout/theme"
import CodeIcon from "@mui/icons-material/Code"
import DrawIcon from "@mui/icons-material/Draw"
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"
import FeatureCard from "./components/FeatureCard"

const code_card = FeatureCard({
  icon: <CodeIcon color="primary" />,
  title: "Code",
  subtitle:
    "Accélérez le développement en générant automatiquement le code de votre application.",
})

const customize_frontend = FeatureCard({
  icon: <DrawIcon color="primary" />,
  title: "Personnaliser",
  subtitle: "Personnalisez aisément le code frontend selon vos exigences.",
})

const deploy = FeatureCard({
  icon: <RocketLaunchIcon color="primary" />,
  title: "Déploie",
  subtitle:
    "Facilitez le déploiement de vos applications avec des conteneurs Docker sur Azure.",
})

export default function Login() {
  const { state, dispatch } = useContext(AuthContext)

  const [data, setData] = useState({ errorMessage: "", isLoading: false })
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const url = window.location.href
    const hasCode = url.includes("?code=")

    if (hasCode) {
      const newUrl = url.split("?code=")
      window.history.pushState({}, null, newUrl[0])
      setData({ ...data, isLoading: true })

      const requestData = {
        code: newUrl[1],
        client_id: state.client_id,
        redirect_uri: state.redirect_uri,
      }

      fetch(state.proxy_url.concat("/authenticate"), {
        method: "POST",
        body: JSON.stringify(requestData),
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch({
            type: "LOGIN",
            payload: { user: data, isLoggedIn: true },
          })
        })
        .catch((error) => {
          setData({
            isLoading: false,
            errorMessage: "Sorry! Login failed",
          })
          setShowError(true) // Show the error Snackbar
        })
    }
  }, [state, dispatch, data])

  const handleCloseError = () => {
    setShowError(false)
  }

  if (state.isLoggedIn) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Box
        position="fixed"
        height="100vh"
        maxWidth={{ xs: "100%", md: "60%" }}
        width={{ xs: "100%", md: "60%" }}
        left={0}
        pt={{ xs: 16, sm: 24 }}
        borderRight={`1px solid ${CARDS_COLOR}`}
      >
        <Grid container justifyContent="center">
          <Grid item xs={6} mb={{ xs: 4, sm: 8 }} sx={{ textAlign: "center" }}>
            <Typography variant="h4" color="secondary" fontWeight="bold">
              _tron.bot_
            </Typography>
          </Grid>

          <Grid item xs={11}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                {code_card}
              </Grid>
              <Grid item xs={12} sm={4}>
                {customize_frontend}
              </Grid>
              <Grid item xs={12} sm={4}>
                {deploy}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        position="fixed"
        height={{ xs: "10vh", md: "100vh" }}
        width={{ xs: "100%", md: "37%" }}
        maxWidth={{ xs: "100%", md: "37%" }}
        right={0}
        px={2}
        mt={{ xs: 4, md: 50 }}
      >
        <Grid container justifyContent={{ xs: "end", md: "center" }}>
          <Grid item xs={6} sm={4} md={6}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={
                <img src="./github-mark.png" alt="github" width="40px" />
              }
              onClick={() => {
                setData({ ...data, errorMessage: "", isLoading: true })

                // Redirect to GitHub OAuth
                setTimeout(() => {
                  window.location.href = `https://github.com/login/oauth/authorize?scope=user&client_id=Iv1.c35c1ccb46825dd2&redirect_uri=${window.location.href}`
                }, 500) // Small delay to show the spinner
              }}
            >
              Login with GitHub
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* <Box pt="30vh" px={4}>
        <LandingHeadline />
      </Box> */}

      {/* Error Snackbar */}
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {data.errorMessage}
        </Alert>
      </Snackbar>

      {/* Full-screen loading overlay */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={data.isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" ml={2}>
          Redirecting to GitHub...
        </Typography>
      </Backdrop>

      <Box
        position="fixed"
        bottom={24}
        right={24}
        backgroundColor={SECONDARY_MAIN}
        sx={{
          cursor: "pointer",
          opacity: 0.6,
          "&:hover": {
            opacity: 1,
          },
          transition: "opacity 0.3s ease",
          textAlign: "center", // Centers the text
        }}
        onClick={() =>
          window.open("https://technologiescodebot.com/", "_blank")
        }
      >
        <img src="logo_text.png" alt="Logo" width="150px" />
      </Box>
    </>
  )
}
